import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import StillHaveQuestions from '../components/StillHaveQuestions'
import HomeNewArrivalSlider from '../components/sliders/NewArrivalSlider'

const ThankYouCatalogRequest = () => {
  return (
    <Layout className={'mx-auto w-full'} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />
      <div className="relative h-[15vh] min-h-[350px] overflow-hidden">
        <div className="bg absolute h-[20vh]" />
        <div className="bg bg2 absolute h-[20vh]" />
        <div className="bg bg3 absolute h-[20vh]" />
        <div className="content flex h-full items-center justify-center">
          <h1 className="text-[calc(3rem+3vw)] font-black drop-shadow-lg">THANK YOU</h1>
        </div>
      </div>
      <div className="mx-auto my-10 flex max-w-[1366px] flex-col items-center justify-center pt-2 md:flex-row">
        <div>
          <StaticImage
            src="../images/thank-you-subscribe.png"
            alt="Catalog Request Thank You Banner"
          />
        </div>

        <div className="mx-auto flex flex-col gap-3 px-10 pb-20 lg:pb-40">
          <h1 className="text-5xl text-red-700">Catalog Request:</h1>
          <p className="my-5 max-w-[800px] text-2xl">
            Thank you for requesting your copy of our Free printed catalog. Your catalog should
            arrive in the address provided very soon.
          </p>
          <h2 className="text-xl font-bold">WHAT WOULD YOU LIKE TO DO NEXT?</h2>
          <Link to="/digital-catalog/" className="button-cta max-w-max">
            BROWSE DIGITAL CATALOG
          </Link>
          <Link to="/what-s-new/" className="button-cta max-w-max">
            Continue Shopping
          </Link>
        </div>
      </div>
      <HomeNewArrivalSlider className="w-10/12" />
      <hr className="w-10/12 mx-auto" />
      <StillHaveQuestions />

      <Map />
    </Layout>
  )
}

export default ThankYouCatalogRequest
